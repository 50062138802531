<template>
    <div class="column is-full block">
      <div class="searchresult-info">
        <strong>{{ $t('search.your_search') }}</strong>
        <b-field grouped group-multiline>
          <div v-if="search.type === 'regular'" class="control">
            <b-taglist attached>
              <b-tag type="is-secondary is-medium">{{$tc('search.keyword', search.primaryWord.length)}}</b-tag>
              <b-tag type="is-primary is-medium" v-for="keyword in search.primaryWord" :key="keyword">{{ keyword }}</b-tag>
            </b-taglist>
          </div>
          <div v-else class="control">
            <b-taglist attached>
              <b-tag type="is-secondary is-medium">{{$t('search.domain')}}</b-tag>
              <b-tag type="is-primary is-medium ">{{ search.url }}</b-tag>
            </b-taglist>
          </div>
          <div v-if="search.params" class="control">
            <b-taglist attached>
              <b-tag type="is-secondary is-medium">{{$tc('search.volume')}}</b-tag>
              <b-tag type="is-primary is-medium">{{ search.params.volumeMin }}</b-tag>
            </b-taglist>
          </div>
          <div v-if="search.params && search.params.country" class="control">
            <b-taglist attached>
              <b-tag type="is-secondary is-medium">{{ $tc('search.country') }}</b-tag>
              <b-tag type="is-primary is-medium">{{ search.params.country.toUpperCase() }}</b-tag>
            </b-taglist>
          </div>
        </b-field>
      </div>
    </div>
</template>

<script>
export default {
  name: "SearchResultInfo",
  props: {
    search: {
      type: Object
    },
  }
}
</script>

<style lang="scss">
.searchresult-info{
  display: flex;
  align-items: center;

  strong{
    margin-right: 15px;
  }
}
</style>
