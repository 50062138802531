<template>
  <div class="columns search-result">
    <keyword-action
        v-if="openNotificationKeyword"
        @close-notification-keyword="handleCloseNotificationKeyword"
        :keywords="checkedKeywords"
        :search-id="search._id"
    />
    <div class="column is-half">
        <vs-table class="result-table" v-model="selected">
          <template #header>
            <vs-input v-model="searchInput" border placeholder="Search"/>
          </template>

          <template #thead style="position: sticky;top: 0;left:0;">
            <vs-tr>
                <vs-th>
                  <vs-tooltip color="primary" border-thick>
                    <vs-checkbox v-model="allCheck" @change="checkedKeywords = $vs.checkAll(checkedKeywords, data)" />
                  <template #tooltip>
                    Select keyword to see actions you can perform on it
                  </template>
                  </vs-tooltip>
                </vs-th>

              <vs-th sort @click="data = $vs.sortData($event ,data, 'score')">
                Score
              </vs-th>

              <vs-th sort @click="data = $vs.sortData($event ,data, 'content')">
                {{ $tc('search.keyword', 2) }}
              </vs-th>
              <vs-th sort @click="data = $vs.sortData($event ,data, 'volumeSemRush')">
                Volume
              </vs-th>
              <vs-th sort @click="data = $vs.sortData($event ,data, 'volResultsGoogle')">
                Competition
              </vs-th>
              <vs-th sort @click="data = $vs.sortData($event ,data, 'costPerClicSemRush')">
                <i class='bx bxs-coin-stack'></i> CPC
              </vs-th>
              <vs-th v-if="search.type === 'url_based'"></vs-th>
            </vs-tr>
          </template>

          <template #tbody>
            <vs-tr
                v-for="(item, index) in $vs.getSearch(data, searchInput)"
                :key="index"
                :data="item"
                :is-selected="selected == item"
            >
              <vs-td>
                <vs-checkbox :val="item" v-model="checkedKeywords"/>
              </vs-td>
              <vs-td>
                <keyword-score :score="item.score" :avg-competition-d-a="item.avgCompetitionDA"/>
              </vs-td>
              <vs-td><a @click="copyToClipboard(item.content)">{{ item.content }}</a></vs-td>
              <vs-td>{{ item.volumeSemRush.toLocaleString() }}</vs-td>
              <vs-td>{{ Number(item.volResultsGoogle) > 0 ? item.volResultsGoogle.toLocaleString() : '0' }}</vs-td>
              <vs-td>{{item.costPerClicSemRush}}</vs-td>
              <vs-td v-if="search.type === 'url_based'">
                {{ item.competition.some(compet => compet.url === typeUrl) ? 'You are in top 3 🥇' : '💪 be brave' }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
    </div>

    <div v-if="selected" class="column">
      <search-result-keyword-detail
          :is-enriched="isEnriched(selected)"
          :keyword="selected"
          :search="search"/>
    </div>
    <div v-else class="column">
      <div class="box detail-hidden center">
        <p class="title">{{$t('search.more')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import SearchResultKeywordDetail from "./SearchResultKeywordDetail";
import KeywordAction from "./KeywordAction";
import KeywordScore from "./KeywordScore";

export default {
  name: "SearchResult",
  components: {
    KeywordScore,
    KeywordAction,
    SearchResultKeywordDetail
  },
  props: {
    search: {
      type: Object
    },
    data: {
      type: Array
    },
    typeUrl: {
      type: String
    },
  },
  data() {
    return {
      allCheck: false,
      checkedKeywords: [],
      searchInput: '',
      selected: null,
      openNotificationKeyword: false,
    }
  },
  watch: {
    data(data) {
      // When getResultsEvent data is updated so need update checkedKeywords
      if (typeof this.checkedKeywords !== 'undefined' && this.checkedKeywords.length > 0){
        const checked = data.filter((keyword) => {
          return this.checkedKeywords.some((checkedKeyword) => {
            return checkedKeyword._id === keyword._id;
          });
        });
        this.checkedKeywords = checked
      }

      if (this.selected){
        this.selected = this.data.find(keyword => keyword._id === this.selected._id)
      }

    },
    checkedKeywords(keywords){
      this.openNotificationKeyword = keywords.length > 0
    }
  },
  methods : {
    /**
     * Check if keyword is enriched,
     * Is enriched if is enriched search (advanced) OR if keyword as competitors and if his (check on first) competitor has DA value
     * @param keyword
     * @returns {boolean}
     */
    isEnriched(keyword){
      if (this.search.enriched || (keyword.competition.length > 0 && keyword.competition[0].domainAuthority)) {
        return true
      } else {
        return false
      }
    },
    handleCloseNotificationKeyword(){
      this.openNotificationKeyword = false
    },
    copyToClipboard(str){
      const tempArea = document.createElement('textarea');

      tempArea.value = str;

      document.body.appendChild(tempArea);

      tempArea.select();

      document.execCommand('copy');

      document.body.removeChild(tempArea);

      this.notificationSuccess(str + ' is copied to clipboard 😉');
    }
  }
}
</script>

<style lang="scss">
.search-result{

  .result-table {
    max-height: 100%;
    .vs-table {
      max-height: 600px;
    }
  }

  .detail-hidden{
    height: 100%;
  }

  .vs-table {
    td,
    th {
      font-size: 15px;
    }
  }
}
</style>
