<template>
    <div class="page-search">
      <semrush-loader/>
      <Loader :loading="loader"/>

        <div class="is-flex ml-6">
          <h1 class="title m-5" style="margin-left: 250px !important; cursor: pointer" @click="renameSearch(search._id)">
            {{$tc('search.name', 1)}} : {{ searchName ? searchName : $t('search.nameless') }}
          </h1>
        </div>


      <div class="box is-half p-5">
          <a @click="$router.go(-1)" class="pr-4">
              <i class='bx bx-arrow-back' style="color:#48a9a6;" />
          </a>
          <div id="speedLoader" class="grid ml-6" v-if="speedLoader">
            <vs-row align="center">
              <vs-col w="10">
                <b-progress type="is-success"/>
              </vs-col>
              <vs-col w="2">
                <search-button-reload
                    @reload-search="handleReloadSearch"
                    :search="search"
                    :project="project"
                />
              </vs-col>
            </vs-row>
            <vs-row>
              <p><i class='bx bx-info-circle'/> We are looking for the most relevant keywords. This operation can take a
                few minutes. If it takes too long, try to reload your search</p>
            </vs-row>
            <br>
          </div>



        <search-result-info :search="search"/>

        <div class="columns">
          <div class="column is-1 is-offset-9">
            <vs-tooltip color="primary" border-thick style="position: relative; top: -50px">
              <vs-button
                  icon
                  color="warning"
                  @click="activeDialog = !activeDialog"
              >
                <i class='bx bxs-edit-alt' />
              </vs-button>
              <template #tooltip>
                {{$t('search.tooltip_update')}}
              </template>
            </vs-tooltip>
          </div>
        </div>

        <div class="center">
          <search-dialog-create
            @handleDialogClose="this.closeDialogUpdate"
            :is-active="this.activeDialog"
            :id-project="this.project._id"
            :name-project="this.project.name"
            :searchParams="this.search"
          />
        </div>


        <!-- Display only if search has results -->
        <search-result v-if="(Array.isArray(data) && data.length > 0)"
                       :data="data"
                       :search="search"
                       :type-url="typeUrl"
        />
        <div v-else-if="reload" class="box center" style="margin-top: 20px; height: 130px">
          <p class="title">{{$t('search.finding')}}</p>
        </div>
        <div class="search-noresult box" v-else>
            <p class="title">{{$t('search.no_result')}} 😞</p>
            <search-button-reload
                :disabled="searchStatus"
                @reload-search="handleReloadSearch"
                :search="search"
                :project="project"
                :text="$t('search.reload')"
            />
          </div>
      </div>
    </div>
</template>

<script>
    import Loader from '../components/Loader'
    import {getSearchResults} from "@/utils/search/getSearchResults";
    import {isASearchRunning, relaunchSearch} from "@/services/extensionMessaging";
    import SemrushLoader from "../components/SemrushLoader";
    import {getProjectById} from "../utils/project/getProjectById";
    import SearchButtonReload from "../components/SearchButtonReload";
    import SearchResult from "../components/SearchResult";
    import SearchResultInfo from "../components/SearchResultInfo";
    import {rename} from "@/utils/search/renameSearch";
    import EventBus from "../services/event-bus";
    import SearchDialogCreate from "@/components/SearchDialogCreate";

    export default {
        name: "Search",
        components: {
          SearchResultInfo,
          SearchResult,
          SearchButtonReload,
          SemrushLoader,
          Loader,
          SearchDialogCreate
        },
        data() {
            return {
                idSearch: '',
                searchName: '',
                search: {},
                project: {},
                data: [],
                avgCompetitionDA : [],
                typeUrl: null,
                loading: true,
                loader: false,
                speedLoader : false,
                reload : false,
                searchStatus: false,
                searchRunning: false,
                activeDialog: false,
            }
        },
      created() {
        this.reload = this.isReload()
        this.searchStatus = localStorage.getItem('searchStatus') == 'true'
        EventBus.$on('updateSearchStatus', this.handleUpdateSearchStatus)
      },
      beforeMount() {
          this.speedLoader = this.reload;
          this.idSearch = this.$route.params.idSearch;
          this.loader = true;

          getSearchResults(this.idSearch)
              .then(res => {

                this.search = res.data.searchResult;
                this.searchName = this.search.name;
                if (!this.reload) {
                  this.data = res.data.searchResult.resultCombineKeyword;
                  this.typeUrl = 'www.' + res.data.searchResult.url;
                  this.loading = false;
                }

                getProjectById(res.data.searchResult.project)
                    .then(res => {
                      this.project = res.data.project
                    })
                    .catch(() => {
                      this.notificationError('An error occurred during data recovery')
                    })

                this.loader = false;



              })
              .catch(() => {
                this.notificationError('Failed to load search result')
              })

        window.addEventListener("searchProgressEvent", () => {
          this.speedLoader = false
        });

        window.addEventListener("getResultsEvent", (event) => {
          console.log(event.detail.result)

          if (event.detail.result && event.detail.result.length === 0) {
            this.reload = false
          }

          getSearchResults(this.idSearch)
              .then(res => {
                if (this.idSearch === res.data.searchResult._id) {
                  this.data = res.data.searchResult.resultCombineKeyword;
                  this.loading = false;
                }
              })
              .catch(() => {
                this.notificationError('Failed to load search result')
              })
        });
      },
      mounted(){
          //pansement de l'espace
          if(this.reload){
            this.searchRunningSecurity(this.idSearch)
          }
      },
      methods: {
        handleUpdateSearchStatus(status) {
          this.searchStatus = status
        },
          /**
           * Check if search is really reloaded
           * @returns {string}
           */
          isReload(){
            let reload = this.$route.params.reload

            // Is reload param is string is not reload is the refresh page
            if (reload === 'true' || reload === 'false'){
              reload = false
            }

            return reload;
          },
          handleReloadSearch() {
            this.speedLoader = true
            this.loading = true
            this.reload = true
            this.$router.replace({params: {reload: true}})
          },
            async searchRunningSecurity(idSearch){
                await this.sleep(20000);
                isASearchRunning().then(res => {
                    if (res ===false){
                        relaunchSearch(idSearch).then( res => { console.log(res)})
                    }
                    return true;
                } )

            },
            async sleep(ms){
                return new Promise(resolve => {
                    setTimeout(
                        function(){ resolve(true)}, ms);
                })
            },
            renameSearch(id) {
                this.$buefy.dialog.prompt({
                    message: 'Send your <B>new name</B> to moon 🚀',
                    inputAttrs: {
                        type: 'text',
                        placeholder: 'My new name is...',
                        value:null
                    },
                    confirmText: 'Send',
                    trapFocus: true,
                    closeOnConfirm: false,
                    onConfirm: (value, {close}) => {
                        this.searchName = value;
                        rename(id, value).then(() =>{
                            this.searchName = value;
                            setTimeout(() => {
                                this.notificationSuccess( `Your new name is send`);
                                close();
                            }, 200);
                        }).catch(() => {
                            this.notificationError( `Failed to rename your search`)
                        });
                        this.$emit('rename-search')
                    },

                    type: 'is-primary',
                })
            },

            closeDialogUpdate(openCreateDialog = false){
              this.activeDialog = false

              if (openCreateDialog) {
                this.searchRunning = false
                this.activeDialog = true
              }
            },

        },




    }
</script>
<style lang="scss">
.page-search {
  .search-noresult{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

//.page-search {
//  display: flex;
//  flex-direction: column;
//  flex: 1;
//}
//
//.card-wrapper {
//  display: flex;
//}
//
//.card-wrapper > div > div {
//  height: 100%;
//  overflow: auto;
//}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 3px;
}
</style>
