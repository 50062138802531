<template>
  <div class="keyword-detail">
    <vs-tooltip color="primary" border-thick>
      <a style="font-size: xx-large;color: #48a9a6;" :href="`https://` + keyword.country + `.semrush.com/analytics/keywordoverview/?q=` + keyword.content + `&db=` + keyword.country + ``" target="_blank"> <p style="text-align: center; margin-bottom: 15px">{{keyword.content.toLocaleString()}}</p></a>
      <template #tooltip>
        Click to see keyword on semrush
      </template>
    </vs-tooltip>

    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child box" style="position: relative">
          <p class="subtitle">{{$t('search.opportunity_score')}}</p>
          <vs-tooltip color="primary" border-thick style="position: absolute; top:13px; right: 4px">
            <i class="fas fa-question-circle" style="position: absolute; top:13px; right: 4px"/>
            <template #tooltip>
              Calculated score based on search volume, number of competitors and competitor's domain authority
            </template>
          </vs-tooltip>
          <div class="title">
            <keyword-score :score="keyword.score" :avg-competition-d-a="keyword.avgCompetitionDA"/>
          </div>

        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box" style="position: relative">
          <p class="subtitle">Volume</p>
          <vs-tooltip color="primary" border-thick style="position: absolute; top:13px; right: 10px">
            <i class="fas fa-question-circle" style="position: absolute; top:13px; right: 10px"/>
            <template #tooltip>
              Volume of people searching this keyword on Google each month (source : Semrush)
            </template>
          </vs-tooltip>
          <p class="title">{{ keyword.volumeSemRush.toLocaleString()}}</p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box" style="position: relative">
          <p class="subtitle">Competition</p>
          <vs-tooltip color="primary" border-thick style="position: absolute; top:13px; right: 10px">
            <i class="fas fa-question-circle" style="position: absolute; top:13px; right: 10px"/>
            <template #tooltip>
              {{$t('search.tooltip_competitor')}}
            </template>
          </vs-tooltip>
          <p class="title">{{ keyword.volResultsGoogle > 0 ? keyword.volResultsGoogle.toLocaleString() : '0' }}</p>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child box" style="position: relative">
          <vs-tooltip color="primary" border-thick style="position: absolute; top:8px; right: 8px">
            <a :href="'https://trends.google.fr/trends/explore?q='+keyword.content +'&geo='+keyword.country.toUpperCase()" target="_blank"><i class="fas fa-external-link-alt" style="position: absolute; top:8px; right: 8px"/></a>
            <template #tooltip>
              {{$t('search.tooltip_trend_link')}}
            </template>
          </vs-tooltip>


          <vs-tooltip color="primary" border-thick style="position: absolute; top:13px; right: 2.8rem">
            <i class="fas fa-question-circle" style="position: absolute; top:13px; right: 2.8rem"/>
            <template #tooltip>
              {{$t('search.tooltip_trend')}}
            </template>
          </vs-tooltip>

          <p class="subtitle">Trend </p>
          <p class="title">
            <b-icon
                :icon="keyword.avgTrend >= 0 ? 'arrow-up' : 'arrow-down'"
                pack="fas"
                :type="keyword.avgTrend >= 0 ? 'is-success' : 'is-danger'"
            />
            {{ keyword.avgTrend }}
          </p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box" style="position: relative">
          <p class="subtitle"><i class='bx bxs-coin-stack'/> CPC</p>
          <vs-tooltip color="primary" border-thick style="position: absolute; top:13px; right: 2.8rem">
            <i class="fas fa-question-circle" style="position: absolute; top:13px; right: 2.8rem"/>
            <template #tooltip>
              Cost Per Clic in $ (source : Semrush)
            </template>
          </vs-tooltip>

          <p class="title">{{ keyword.costPerClicSemRush }}$</p>
        </article>
      </div>
    </div>

    <!-- COMPETITORS !-->
    <div class="tile is-ancestor">
      <div class="keyword-competitors tile is-parent">
        <article class="tile is-child box">
          <div class="content">
            <p class="title is-4">{{ $t('search.competitor.overview') }}</p>
            <div class="content" style="position: relative">
            <vs-button
                  :disabled="searchStatus"
                  @click="sendEnrichment"
                  class="button-enrich"
                  :loading="this.auditCompetitorLoading"
                  v-if="isBlurred && keyword.competition.length"
                  size="xl"
                  dark
              >
                <i class="fas fa-bolt"/> {{$t('search.audit_competitor')}}
              </vs-button>
              <vs-table v-blur="keyword.competition.length ? isBlurred : false" v-bind:class="{'is-blurred' : keyword.competition.length ? isBlurred : true}">
                <template #thead>
                  <vs-tr>
                    <vs-th>
                      <vs-tooltip color="primary" border-thick >
                        {{$t('search.competitor.name')}}
                        <template #tooltip>
                          Firsts 10 results ranking on this keyword (source : Google)
                        </template>
                      </vs-tooltip>

                    </vs-th>

                    <vs-th>
                      <vs-tooltip color="primary" border-thick >
                        DA
                        <template #tooltip>
                          Semrush Domain Authority
                        </template>
                      </vs-tooltip>

                    </vs-th>

                    <vs-th>
                      <vs-tooltip color="primary" border-thick >
                        TF
                        <template #tooltip>
                           TrustFlow (source : Majestic)
                        </template>
                      </vs-tooltip>

                    </vs-th>

                    <vs-th>
                      <vs-tooltip color="primary" border-thick >
                        CF
                        <template #tooltip>
                           Citation Flow (source : Majestic)
                        </template>
                      </vs-tooltip>

                    </vs-th>

                    <vs-th>
                      <vs-tooltip color="primary" border-thick >
                        TTF
                        <template #tooltip>
                          Main Tropical Trust Flow (source : Majestic)
                        </template>
                      </vs-tooltip>

                    </vs-th>

                    <vs-th>
                      <vs-tooltip color="primary" border-thick >
                        Traffic
                        <template #tooltip>
                          Estimated domain's traffic (source : Semrush)
                        </template>
                      </vs-tooltip>

                    </vs-th>
                  </vs-tr>
                </template>
                <template #tbody>
                  <vs-tr
                      v-for="(competitor, index) in keyword.competition"
                      :key="index"
                      :data="competitor"
                  >
                    <vs-td>
                      <a :href="'https://'+competitor.url" target="_blank"><i class='bx bx-link-external'/> {{ competitor.url }}</a>
                    </vs-td>
                    <vs-td>{{competitor.domainAuthority}}</vs-td>
                    <vs-td>{{competitor.trustFlow}}</vs-td>
                    <vs-td>{{competitor.citationFlow}}</vs-td>
                    <vs-td>{{competitor.tropicalTrustFlow}}</vs-td>
                    <vs-td>{{competitor.uniqueVisitors ? competitor.uniqueVisitors.toLocaleString() : competitor.uniqueVisitors}}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vBlur from 'v-blur'
import {enrichSearch} from "@/utils/search/enrich";
import KeywordScore from "./KeywordScore";

Vue.use(vBlur)
export default {
  name: "SearchResultKeywordDetail",
  components: {KeywordScore},
  props:{
    isEnriched: {
      type: Boolean
    },
    keyword: {
      type: Object
    },
    search: {
      type: Object
    }
  },
  data(){
    return {
      isBlurred: false,
      auditCompetitorLoading: false,
    }
  },
  computed: {
    searchStatus(){
      return localStorage.getItem('searchStatus') == 'true'
    }
  },
  created() {
    this.isBlurred = !this.isEnriched

    window.addEventListener("searchProgressEvent", (event) => {
      // End of enrichment
      if (event.detail.info.valueProgress === event.detail.info.maxProgress) {
        this.auditCompetitorLoading = false
      }
    });
  },
  watch: {
   keyword(){
     this.isBlurred = !this.isEnriched
   }
  },
  methods:{
    sendEnrichment(){
      this.auditCompetitorLoading = true

      enrichSearch(this.search._id, [this.keyword]).then(()=>{
      })
    }
  }
}
</script>

<style lang="scss">

.keyword-detail{
  article{
    text-align: center;
  }

  .keywordscore-button{
    display: inline;
    font-size: 1rem;
    border-radius: 15px;
  }

  .keyword-competitors article{
    text-align: initial;
  }
}

.button-enrich{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  &:hover{
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  i{
    margin-right: 3px;
  }
}

.is-blurred{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  pointer-events: none;

}

</style>
