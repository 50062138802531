<template>
  <div class="notification-keyword notification-parent vs-notification-parent vs-notification-parent--top-center">
    <div class="notification vs-notification vs-notification--color vs-notification--sticky vs-notification--dark vs-notification--width-auto notification-enter-to" style="padding: 20px;">
      <div class="vs-notification__content">
        <div class="vs-notification__content">
          <div class="notification-content">
            <vs-avatar size="30">
              <template #text>
                {{keywords.length}}
              </template>
            </vs-avatar>
            <p style="margin:0 10px">{{ $tc('search.selected') }}</p>

            <vs-button
                color="#FFF"
                border
                @click="sendEnrichment"
            >
              <i class="fas fa-bolt" style="margin-right: 3px"/>{{$t('search.audit_competitor')}}
            </vs-button>

            <download-csv
                class   = "btn btn-default"
                :data   = "dataToExport"
                name    = "search_results_semantik.csv"
                :labels="labels"
                :fields="fields"
                :separator-excel = "true"
            >
              <vs-button
                  color="#FFF"
                  border
                  @click="processData"
              >
                <i class="bx bxs-download" style="margin-right: 3px"/> {{ $tc('search.export') }}
              </vs-button>
            </download-csv>

          </div>
        </div>
      </div>
      <button class="vs-notification__close" @click="close()">
        <i class="vs-icon-close vs-icon-hover-less"></i>
      </button>
    </div>
  </div>
</template>

<script>
import {enrichSearch} from "@/utils/search/enrich";
import JsonCSV from 'vue-json-csv'
import Vue from "vue";

Vue.component('downloadCsv', JsonCSV);

export default {
  name: "KeywordAction",
  props: {
    keywords: {
      type: Array
    },
    searchId: {
      type: String
    },
  },
  data(){
    return {
      dataToExport : [],
      labels : {
        'content' : 'Content',
        'score' : 'Score',
        'volumeSemRush' : 'Volume',
        'volResultsGoogle' : 'Competition by Google',
        'competitionSemRush' : 'Competition by SemRush',
        'costPerClicSemRush' : 'CPC SemRush ($)',
        'avgTrend' : 'Average Trend',
        'trendSemRush' : 'Trend',
        'avgCompetitionDA.top1' : 'Avg DA Top1',
        'avgCompetitionDA.top3' : 'Avg DA Top 3',
        'avgCompetitionDA.top10' : 'Avg DA top10',
        'competition[0].url' : 'First competitor domain',
        'competition[1].url' : 'Second competitor domain',
        'competition[2].url' : 'Third competitor domain'
      },
      fields : ['content', 'score', 'volumeSemRush', 'volResultsGoogle', 'competitionSemRush', 'costPerClicSemRush', 'avgTrend', 'trendSemRush', 'avgCompetitionDA.top1', 'avgCompetitionDA.top3', 'avgCompetitionDA.top10', 'competition[0].url', 'competition[1].url' ,'competition[2].url' ]
    }
  },
  methods: {
    close() {
      this.$emit('close-notification-keyword')
    },
    sendEnrichment(){
      enrichSearch(this.searchId, this.keywords).then(res=>{
        console.log(res.data)
      })
    },
    processData() {
      this.keywords.forEach(keyword => {
        this.dataToExport.push({
          content: keyword.content,
          'score': keyword.score,
          'volumeSemRush': keyword.volumeSemRush,
          'volResultsGoogle': keyword.volResultsGoogle,
          'competitionSemRush': keyword.competitionSemRush,
          'costPerClicSemRush': keyword.costPerClicSemRush,
          'avgTrend': keyword.avgTrend,
          'trendSemRush': keyword.trendSemRush,
          'avgCompetitionDA.top1': keyword.avgCompetitionDA ? keyword.avgCompetitionDA.top1 : "no data",
          'avgCompetitionDA.top3': keyword.avgCompetitionDA ? keyword.avgCompetitionDA.top3 : "no data",
          'avgCompetitionDA.top10': keyword.avgCompetitionDA ? keyword.avgCompetitionDA.top10 : "no data",
          'competition[0].url': keyword.competition ? keyword.competition[0] !== undefined ? keyword.competition[0].url : "no data" : "no data",
          'competition[1].url': keyword.competition ? keyword.competition[1] !== undefined ? keyword.competition[1].url : "no data" : "no data",
          'competition[2].url': keyword.competition ? keyword.competition[2] !== undefined ? keyword.competition[2].url : "no data" : "no data",
        })

      })
    },
  }
}
</script>

<style lang="scss">

.notification-keyword{
  .vs-button:focus{
    background: none;
  }

  .notification-content{
    display: flex;
    align-items: center;
  }
}
</style>
