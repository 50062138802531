<template>
  <b-tooltip
      v-if="avgCompetitionDA"
      :label="avgCompetitionDA.top3 <= 30 ? 'Reaching Top 3 is easy (DA<30)' : avgCompetitionDA.top3 <= 60 ? 'Reaching Top 3 is medium (DA<60)' : 'Reaching Top 3 is hard (DA>60)'"
      multilined
      append-to-body
  >
    <vs-button
        flat
        :style="avgCompetitionDA.top3 <= 30 ? 'color: black' : avgCompetitionDA.top3 <= 60 ? 'color: black' : ''"
        class="keywordscore-button"
        :success="avgCompetitionDA.top3 <= 30"
        :warn="avgCompetitionDA.top3 > 30 && avgCompetitionDA.top3 <= 60"
    >
      {{ score.toLocaleString() }}
    </vs-button>
  </b-tooltip>
  <vs-button
      v-else
      class="keywordscore-button"
  >
    {{ score.toLocaleString() }}
  </vs-button>
</template>

<script>
export default {
  name: "KeywordScore",
  props:{
    avgCompetitionDA:{
      type: Object
    },
    score: {
      type: [String, Number],
      required: true
    }
  }
}
</script>

<style scoped>

</style>
