import axios from "axios";
import {getCookie} from "../cookie/cookie";

export function enrichSearch(searchId, keywords) {
    let burl = process.env.VUE_APP_API;
    console.log(burl);
    return axios({
        method: 'post',
        url: burl + '/api/project/search/enrich_search',
        data : {searchId, keywords},
        headers : {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + getCookie("token")
        }
    })
}


